import React, { useContext } from 'react';

import { Button } from '@iwoca/orion';

import styles from './ConnectButton.module.css';
import { ActivationContext } from '../../../../activation/Activation';
import { LinkWithQuery } from '../../../../components/LinkWithQuery/LinkWithQuery';
import { LockedButton } from '../../../../components/LockedButton/LockedButton';

export function ConnectButton({
  children,
  isConnected = false,
  link,
  isExternal,
  onClick,
}: {
  children: React.ReactNode;
  isConnected?: boolean;
  link?: string;
  isExternal?: boolean;
  onClick?: () => void;
}) {
  const { isOnboarded, isLoading } = useContext(ActivationContext);

  if (isLoading) return null;

  if (!isOnboarded) return <LockedButton />;

  if (onClick)
    return (
      <Button
        variant={isConnected ? 'secondary-alt' : 'primary'}
        className={styles.connectButton}
        onClick={onClick}
      >
        {children}
      </Button>
    );

  if (isExternal)
    return (
      <a href={link} rel="noopener noreferrer">
        <Button
          variant={isConnected ? 'secondary-alt' : 'primary'}
          className={styles.connectButton}
        >
          {children}
        </Button>
      </a>
    );

  return (
    <>
      <LinkWithQuery className={styles.connectButtonWrapper} to={link!}>
        <Button
          variant={isConnected ? 'secondary-alt' : 'primary'}
          className={styles.connectButton}
        >
          {children}
        </Button>
      </LinkWithQuery>
    </>
  );
}
