export const XeroFormTitle = ({
  firstTime = true,
}: {
  firstTime?: boolean;
}) => {
  return firstTime ? (
    <div className="mx-auto mt-3xl flex w-[500px] flex-col gap-m text-left">
      <h1 className="m-0 mb-xl mt-xl text-4xl">Link your Xero to iwocaPay</h1>
      <div className="mb-l text-l font-reg">
        <p className="mt-0 pt-0">
          Just select the Xero account to link and the invoice branding theme
          you’d like to add a ‘pay with iwocaPay’ button to.
        </p>
        <p>You’ll be able to unlink at any time from this page.</p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold text-primary-40 no-underline"
          href="https://support.iwoca.co.uk/en/articles/4670005-connecting-your-xero-account-to-iwocapay"
        >
          Find out more in our Xero guide.
        </a>
      </div>
    </div>
  ) : (
    <div className="mx-auto mt-3xl flex w-[500px] flex-col gap-m text-left">
      <h1 className="m-0 mb-xl mt-xl text-4xl">Update your Xero details</h1>
      <div className="mb-l text-l font-reg">
        <p className="mt-0 pt-0">
          You can change the Xero account to link and/or the invoice branding
          theme you want a ‘pay with iwocaPay’ button on below.
        </p>
        <p>Once you hit submit the changes will be live.</p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold text-primary-40 no-underline"
          href="https://support.iwoca.co.uk/en/articles/4670005-connecting-your-xero-account-to-iwocapay"
        >
          Find out more in our Xero guide.
        </a>
      </div>
    </div>
  );
};
