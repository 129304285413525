import { IconNames } from '@iwoca/orion/lib/components/icon/Icon';

import { ValidRoutePaths } from '../../../routing/utils';

type TMenuIcon = {
  normal: IconNames;
  selected: IconNames;
};

type TExternalLink = {
  href: string;
  newTab: boolean;
};

type TMenuLink = {
  title: string;
  internal_path?: ValidRoutePaths;
  external_link?: TExternalLink;
  onClick?: () => void;
  icon?: TMenuIcon | null;
};

export const baseLinks: TMenuLink[] = [
  {
    title: 'Home',
    internal_path: '/pay/dashboard',
    icon: { normal: 'homeOutline', selected: 'homeFilled' },
  },
  {
    title: 'Pay Links',
    internal_path: '/pay/pay-links/universal',
    icon: { normal: 'link', selected: 'link' },
  },
  {
    title: 'Payments',
    internal_path: '/pay/payments',
    icon: { normal: 'creditCardFilled', selected: 'creditCardFilled' },
  },
  {
    title: 'Fees',
    internal_path: '/pay/fees',
    icon: { normal: 'pound', selected: 'pound' },
  },
  {
    title: 'Spending Limits',
    internal_path: '/pay/spending-limits',
    icon: { normal: 'presentationOutline', selected: 'presentationFilled' },
  },
  {
    title: 'Integrations',
    internal_path: '/pay/integrations',
    icon: { normal: 'bricksOutline', selected: 'bricksFilled' },
  },
];

export const secondaryLinks: TMenuLink[] = [
  {
    title: 'API docs',
    external_link: { href: 'https://iwoca.co.uk/ecom/docs/', newTab: true },
    icon: { normal: 'code', selected: 'code' },
  },
  {
    title: 'Launch pack',
    external_link: {
      href: 'https://www.coda.io/d/Welcome-to-iwocaPay_dx9e47R5IPf',
      newTab: true,
    },
    icon: { normal: 'rocket', selected: 'rocket' },
  },
  {
    title: 'Refer a business',
    internal_path: '/pay/rewards',
    icon: { normal: 'chatOutline', selected: 'chatFilled' },
  },
];
