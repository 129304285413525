import { useContext, useState } from 'react';

import { tracking } from '@iwoca/frontend-tracking-library';
import { Button, Icon } from '@iwoca/orion';
import { IconNames } from '@iwoca/orion/lib/components/icon/Icon';
import classnames from 'classnames';

import { HelpModal } from './components/TelephoneNavItem/HelpModal';
import { baseLinks, secondaryLinks } from './items/items';
import styles from './SellerNavBar.module.css';
import { ActivationContext } from '../../activation/Activation';
import {
  useGetContext,
  useGetSharedFunctionality,
} from '../../api/lending/lapiHooks';
import { buildQueryString } from '../../Buyer/utils/queryParams';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { useUserGroups } from '../../hooks/useUserGroups';
import { ValidRoutePaths } from '../../routing/utils';
import { NameTag } from '../../Seller/Dashboard/components/SellerDashboardHeader/SellerDashboardHeader';
import { useGetSellerSpendingLimitRequest } from '../../Seller/SpendingLimitRequests/hooks/useGetSellerSpendingLimitRequest.hook';
import { SafeLink } from '../SafeLink/SafeLink';
import iwocaPayLogo from '../svg/iwocaPayLogo.svg';

type TMenuIcon = {
  normal: IconNames;
  selected: IconNames;
};

type TExternalLink = {
  href: string;
  newTab: boolean;
};

export const CreatePayLinkButton = () => {
  const { navigate } = useSafeNavigate();
  return (
    <Button
      className={styles.createPayLink}
      onClick={() =>
        navigate({ to: '/pay/pay-links/universal', keepParams: true })
      }
    >
      Create Pay Link
      <Icon icon="plus" className={styles.createPayLinkIcon} />
    </Button>
  );
};

export const SellerNavBar = () => {
  const {
    context: { isLoggedIn },
  } = useGetContext();
  const { isOnboarded } = useContext(ActivationContext);
  const { navigate } = useSafeNavigate();
  const { isOwner, isStaff, isAdmin, isContributor } = useUserGroups();
  const { spendingLimitRequests } = useGetSellerSpendingLimitRequest();
  const [helpModalVisible, setHelpModalVisible] = useState<boolean>(false);
  const { functionality } = useGetSharedFunctionality();

  let baseMenuItems = baseLinks;
  if (spendingLimitRequests.length === 0) {
    baseMenuItems = baseMenuItems.filter(
      (menuItem) => menuItem.title !== 'Spending Limits',
    );
  }

  // @ts-expect-error This will auto-resolve when updating lapi-client
  if (!functionality?.can_access_seller_fee_dashboard) {
    baseMenuItems = baseMenuItems.filter(
      (menuItem) => menuItem.title !== 'Fees',
    );
  }

  if (isContributor) {
    baseMenuItems = baseMenuItems.filter(
      (menuItem) => menuItem.title !== 'Integrations',
    );
  }

  let secondaryMenuItems = secondaryLinks;
  if (!isOnboarded) secondaryMenuItems = [];

  const LoggedInItems = () => {
    return (
      <nav className={styles.navBarDrawer} aria-label="Main Menu">
        <div>
          <SafeLink to={'/pay/dashboard'}>
            <img
              src={iwocaPayLogo}
              className={styles.logo}
              alt="iwocaPay logo"
              aria-hidden="true"
            />
          </SafeLink>
          <div className={styles.userInfo}>
            <NameTag />
          </div>

          {isOnboarded ? (
            <Button
              className={styles.createPayLink}
              onClick={() =>
                navigate({ to: '/pay/pay-links/universal', keepParams: true })
              }
            >
              Create Pay Link
              <Icon icon="plus" className={styles.createPayLinkIcon} />
            </Button>
          ) : (
            <Button
              className={styles.createPayLink}
              onClick={() =>
                navigate({ to: '/pay/activation', keepParams: true })
              }
            >
              Get started
            </Button>
          )}

          <div className={styles.menuBar}>
            {baseMenuItems.map((menuItem, index) => (
              <MenuItem
                key={index}
                title={menuItem.title}
                internal_path={menuItem.internal_path}
                icon={menuItem.icon}
              />
            ))}
          </div>
        </div>
        <div>
          <div className={styles.midOptions}>
            {(isStaff || isOwner) && (
              <>
                <MenuItem
                  title="Loans dashboard"
                  icon={{
                    normal: 'exchange',
                    selected: 'exchange',
                  }}
                  compact={true}
                  onClick={() => {
                    tracking.ampli.switchedToCoreBusinessDashboard();
                    window.location.href = `/account/${buildQueryString()}`;
                  }}
                />
                <hr className={styles.divider} />
              </>
            )}
            {secondaryMenuItems.map((menuItem, index) => (
              <MenuItem
                key={index}
                title={menuItem.title}
                external_link={menuItem.external_link}
                internal_path={menuItem.internal_path}
                icon={menuItem.icon}
                compact={true}
              />
            ))}
            {(isStaff || isOwner || isAdmin) && (
              <MenuItem
                title="Manage users"
                internal_path="/pay/manage-users"
                icon={{
                  normal: 'multipleUsersOutline',
                  selected: 'multipleUsersFilled',
                }}
                compact={true}
              />
            )}
            <MenuItem
              title="Manage account"
              internal_path="/pay/manage-account"
              icon={{
                normal: 'userCog',
                selected: 'userCog',
              }}
              compact={true}
            />
            <hr className={styles.divider} />
            <MenuItem
              title="FAQs"
              external_link={{
                href: 'https://support.iwoca.co.uk/en/collections/2145896-what-s-iwocapay',
                newTab: true,
              }}
              compact={true}
            />
            <MenuItem
              title="Help"
              onClick={() => {
                setHelpModalVisible(true);
              }}
              compact={true}
            />
            <MenuItem
              title="Logout"
              external_link={{ href: '/my_account/logout/', newTab: false }}
              compact={true}
            />
          </div>
        </div>
      </nav>
    );
  };

  const NotLoggedInItems = () => {
    return (
      <nav className={styles.navBarDrawer} aria-label="Main Menu">
        <div>
          <SafeLink to={'/pay/dashboard'}>
            <img
              src={iwocaPayLogo}
              className={styles.logo}
              alt="iwocaPay logo"
              aria-hidden="true"
            />
          </SafeLink>
        </div>
        <div>
          <div className={styles.midOptions}>
            <hr className={styles.divider} />
            <MenuItem
              title="FAQs"
              external_link={{
                href: 'https://support.iwoca.co.uk/en/collections/2145896-what-s-iwocapay',
                newTab: true,
              }}
              compact={true}
            />
            <MenuItem
              title="Help"
              onClick={() => {
                setHelpModalVisible(true);
              }}
              compact={true}
            />
            <MenuItem
              title="Logout"
              external_link={{ href: '/my_account/logout/', newTab: false }}
              compact={true}
            />
          </div>
        </div>
      </nav>
    );
  };

  return (
    <>
      <HelpModal
        isOpen={helpModalVisible}
        closeModal={() => setHelpModalVisible(false)}
      />
      {isLoggedIn ? <LoggedInItems /> : <NotLoggedInItems />}
    </>
  );
};

const MenuItem = ({
  title,
  internal_path,
  external_link,
  onClick,
  compact = false,
  icon = null,
}: {
  title: string;
  internal_path?: ValidRoutePaths;
  external_link?: TExternalLink;
  onClick?: () => void;
  compact?: boolean;
  icon?: TMenuIcon | null;
}): JSX.Element => {
  const isSelected: boolean = window.location.pathname.startsWith(
    internal_path as string,
  );

  const ButtonComponent = () => {
    return (
      <div
        className={classnames(styles.menuBarItem, {
          [styles.menuBarItemSelected]: isSelected,
          [styles.menuBarItemCompact]: compact,
        })}
      >
        {icon && (
          <Icon
            icon={isSelected ? icon.selected : icon.normal}
            aria-hidden="true"
          />
        )}
        {title}
      </div>
    );
  };

  if (external_link) {
    return (
      <a href={external_link.href} target="_blank" rel="noopener noreferrer">
        <ButtonComponent />
      </a>
    );
  }

  if (internal_path) {
    return (
      <SafeLink to={internal_path}>
        <ButtonComponent />
      </SafeLink>
    );
  }

  if (onClick) {
    return (
      <button onClick={onClick} className={styles.noStylingButton}>
        <ButtonComponent />
      </button>
    );
  }

  return <ButtonComponent />;
};
