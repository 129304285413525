import {
  fetchDeleteXeroConnections,
  fetchGetXeroAccounts,
  fetchGetXeroConnections,
  fetchGetXeroIwocapaySellerAccount,
  fetchGetXeroIwocapaySellerBrandingThemes,
} from '@iwoca/lapi-client/edge';
import { useQuery } from '@tanstack/react-query';

import { useStateKey } from '../../hooks/useStateKey.hook';

export const unlinkXero = async (stateKey: string, onUnlink: () => void) => {
  const response = await fetchDeleteXeroConnections({
    reason: 'iwocapay_seller',
    stateKey,
  });

  if (response.ok) {
    onUnlink();
  }
};

export const useGetXeroConnections = () => {
  const { stateKey } = useStateKey();

  const { data, error, isLoading } = useQuery({
    queryKey: ['fetchGetXeroConnectionsIwocapaySeller'],
    queryFn: () =>
      fetchGetXeroConnections({
        reason: 'iwocapay_seller',
        stateKey: stateKey!,
      }),
  });

  return { data, error, isLoading };
};

export const useGetXeroAccounts = (type: 'payment' | 'expense') => {
  const { stateKey } = useStateKey();

  const { data, error, isLoading } = useQuery({
    queryKey: ['fetchGetXeroAccounts', type],
    queryFn: async () => {
      const res = await fetchGetXeroAccounts({
        reason: 'iwocapay_seller',
        stateKey: stateKey!,
        query: {
          account_type: type,
        },
      });
      const activeAccounts = res.data?.accounts?.filter(
        (account) => account.status === 'ACTIVE',
      );

      if (type === 'expense') {
        const activeExpenseAccounts =
          activeAccounts?.filter((account) => account.type === 'EXPENSE') || [];
        const bankFeeAccount =
          res.data?.accounts?.filter((account) => account.code === '404') || [];

        return [...activeExpenseAccounts, ...bankFeeAccount];
      }

      return activeAccounts;
    },
    enabled: !!stateKey,
  });

  return { data, error, isLoading };
};

export const useGetXeroThemes = () => {
  const { stateKey } = useStateKey();

  const { data, error, isLoading } = useQuery({
    queryKey: ['fetchGetXeroIwocapaySellerBrandingThemes'],
    queryFn: () =>
      fetchGetXeroIwocapaySellerBrandingThemes({ stateKey: stateKey! }),
    enabled: !!stateKey,
  });

  return { data, error, isLoading };
};

export const useIwocapaySellerXeroAccount = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}) => {
  const { stateKey } = useStateKey();

  const { data, error, isLoading } = useQuery({
    queryKey: ['fetchGetXeroIwocapaySellerAccount'],
    queryFn: () => fetchGetXeroIwocapaySellerAccount({ stateKey: stateKey! }),
    enabled: !!stateKey && enabled,
  });

  return { data, error, isLoading };
};
