import { Button } from '@iwoca/orion';

import { useSafeNavigate } from '../../../hooks/useSafeNavigate';

export const AuthorisationFailed = () => {
  const { navigate } = useSafeNavigate();
  return (
    <div className="max-w-sm">
      <h2>We weren’t able to authorise you, please try again</h2>
      <Button onClick={() => navigate({ to: '/pay/integrations' })}>
        Try again
      </Button>
    </div>
  );
};
