import { useEffect, useMemo, useState } from 'react';

import {
  fetchPostXeroIwocapaySellerAccount,
  fetchPostXeroIwocapaySellerBrandingThemesPaymentServices,
  fetchPutXeroIwocapaySellerAccount,
} from '@iwoca/lapi-client/edge';
import { Button, Message } from '@iwoca/orion';
import classNames from 'classnames';

import { MultiSelect } from '../../../components/MultiSelect/MultiSelect';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { AuthorisationFailed } from '../Authorisation/AuthorisationFailed';
import {
  unlinkXero,
  useGetXeroAccounts,
  useGetXeroConnections,
  useGetXeroThemes,
  useIwocapaySellerXeroAccount,
} from '../Xero.helpers';
import { XeroFormLoading } from '../XeroFormLoading';
import { XeroFormTitle } from '../XeroFormTitle';

export const XeroSetupForm = ({
  firstTime = true,
}: {
  firstTime?: boolean;
}) => {
  const { stateKey } = useStateKey();
  const { navigate } = useSafeNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string | null }>({});
  type TOption = { value: string; label: string };
  const [selectedThemes, setSelectedThemes] = useState<TOption[]>([]);

  const {
    data: connections,
    error: connectionsError,
    isLoading: loadingConnections,
  } = useGetXeroConnections();

  const {
    data: paymentAccounts,
    error: paymentAccountsError,
    isLoading: loadingPaymentAccounts,
  } = useGetXeroAccounts('payment');

  const {
    data: feeAccounts,
    error: feeAccountsError,
    isLoading: laodingFeeAccounts,
  } = useGetXeroAccounts('expense');

  const {
    data: xeroInvoiceThemes,
    error: invoiceThemesError,
    isLoading: loadingInvoiceThemes,
  } = useGetXeroThemes();

  const { data: iwocapayXeroAccount } = useIwocapaySellerXeroAccount();

  const initial_connection_id =
    connections?.data.connections?.find(
      (connection) =>
        connection.tenant_id ===
        iwocapayXeroAccount?.data?.seller_account?.tenant?.tenant_id,
    )?.tenant_id || connections?.data.connections[0].tenant_id;

  const initial_paymentAccount_id = firstTime
    ? undefined
    : iwocapayXeroAccount?.data?.seller_account?.payment_account
        ?.payment_account_id;

  const initial_feeAccount_id = firstTime
    ? undefined
    : // @ts-expect-error fee_account needs to be added to endpoint response
      iwocapayXeroAccount?.data?.seller_account?.fee_account?.fee_account_id;

  const initial_invoiceTheme_ids = useMemo(
    () =>
      firstTime
        ? []
        : xeroInvoiceThemes?.data?.branding_themes
            ?.filter((theme) => {
              const isIwocapayTheme = theme.payment_services?.find(
                (service) => service.payment_service_name === 'iwocaPay',
              );

              return !!isIwocapayTheme;
            })
            .map((theme) => ({
              value: theme.branding_theme_id || '',
              label: theme.name || '',
            })) || [],
    [firstTime, xeroInvoiceThemes],
  );

  useEffect(() => {
    setSelectedThemes(initial_invoiceTheme_ids);
  }, [initial_invoiceTheme_ids]);

  const validate = (form: HTMLFormElement) => {
    const formData = Object.fromEntries(new FormData(form));

    const organisation_id = formData.organisation?.toString();
    const paymentAccount_id = formData.paymentAccount?.toString();
    const feeAccount_id = formData.feeAccount?.toString();
    const invoiceThemes_ids = selectedThemes.map((theme) => theme.value);

    const isValid =
      !!organisation_id &&
      !!paymentAccount_id &&
      !!feeAccount_id &&
      invoiceThemes_ids.length > 0;

    const data = {
      isValid: isValid,
      organisation_id,
      paymentAccount_id,
      feeAccount_id,
      invoiceThemes_ids,
    };

    if (!isValid) {
      setErrors({
        organisation: !organisation_id ? 'Please select an organisation' : null,
        paymentAccount: !paymentAccount_id
          ? 'Please select a Payment Account'
          : null,
        feeAccount: !feeAccount_id ? 'Please select a Fee Account' : null,
        invoiceThemes:
          invoiceThemes_ids.length < 1
            ? 'Please select at least one Invoice Branding Theme'
            : null,
      });
    }

    return data;
  };

  const submit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const {
      isValid,
      organisation_id,
      paymentAccount_id,
      feeAccount_id,
      invoiceThemes_ids,
    } = validate(ev.currentTarget);

    if (!isValid) {
      return;
    }

    try {
      await fetchPostXeroIwocapaySellerAccount({
        stateKey: stateKey!,
        body: {
          data: {
            xero_tenantid: organisation_id,
          },
        },
      });
    } catch (err) {
      console.error('Post Xero Iwocapay Seller Account failed: ', err);
      setIsSubmitting(false);
      return;
    }

    try {
      await fetchPutXeroIwocapaySellerAccount({
        stateKey: stateKey!,
        body: {
          data: {
            payment_account_id: paymentAccount_id!,
            fee_account_id: feeAccount_id,
          },
        },
      });
    } catch (err) {
      console.error('Put Xero Iwocapay Seller Account failed: ', err);
      setIsSubmitting(false);
      return;
    }

    try {
      await fetchPostXeroIwocapaySellerBrandingThemesPaymentServices({
        stateKey: stateKey!,
        body: {
          data: {
            branding_themes: invoiceThemes_ids!.map((id) => ({
              branding_theme_id: id,
            })),
          },
        },
      });
    } catch (err) {
      console.error('Post Xero Branding Themes failed: ', err);
      setIsSubmitting(false);
      return;
    }

    // @ts-expect-error ValidRoutePaths doesn't recognice the "xero" wildcard
    navigate({ to: '/pay/integrations/xero/' });
  };

  if (
    connectionsError ||
    paymentAccountsError ||
    feeAccountsError ||
    invoiceThemesError ||
    connections?.data.connections.length === 0
  ) {
    return <AuthorisationFailed />;
  }

  if (
    loadingConnections ||
    loadingPaymentAccounts ||
    laodingFeeAccounts ||
    loadingInvoiceThemes
  ) {
    return <XeroFormLoading firstTime={firstTime} />;
  }

  return (
    <>
      <XeroFormTitle firstTime={firstTime} />
      <form
        className="mx-auto flex w-[500px] flex-col gap-m"
        onSubmit={submit}
        onChange={(ev) => setErrors({})}
      >
        <div className="flex flex-col items-start gap-xs">
          <label
            htmlFor="organisation"
            className="inline-block align-middle font-med text-primary-10"
          >
            Organisation
          </label>
          <select
            id="organisation"
            name="organisation"
            // below mimics the disabled attribute but allows the value to be submitted rather than ignored
            className={classNames(
              "text-primary-10', w-full rounded-s border border-structure-80 p-m font-med",
              {
                'pointer-events-none bg-structure-95 text-structure-60':
                  connections?.data.connections?.length === 1,
                'bg-structure-100': connections?.data.connections?.length !== 1,
              },
            )}
            tabIndex={connections?.data.connections?.length === 1 ? -1 : 0}
            aria-readonly={connections?.data.connections?.length === 1}
            defaultValue={initial_connection_id}
          >
            {connections?.data.connections?.map(
              ({ tenant_id, tenant_name }) => (
                <option key={tenant_id} value={tenant_id}>
                  {tenant_name}
                </option>
              ),
            )}
          </select>
          {errors.organisation && (
            <Message
              type="error"
              className="mt-xs"
              message={errors.organisation}
            />
          )}
        </div>

        <div className="flex flex-col items-start gap-xs">
          <label
            htmlFor="paymentAccount"
            className="inline-block align-middle font-med text-primary-10"
          >
            Payment Account
          </label>
          <select
            id="paymentAccount"
            name="paymentAccount"
            // below mimics the disabled attribute but allows the value to be submitted rather than ignored
            className={classNames(
              "text-primary-10', w-full rounded-s border border-structure-80 bg-structure-100 p-m font-med disabled:bg-structure-95",
              {
                'pointer-events-none bg-structure-95 text-structure-60':
                  !!initial_paymentAccount_id,
                'bg-structure-100': !!initial_paymentAccount_id,
              },
            )}
            defaultValue={initial_paymentAccount_id || ''}
            aria-readonly={!!initial_paymentAccount_id}
          >
            <>
              {!initial_paymentAccount_id && (
                <option value="" disabled>
                  Select a payment account
                </option>
              )}
              {paymentAccounts?.map(({ account_id, name }) => (
                <option key={account_id} value={account_id}>
                  {name}
                </option>
              ))}
            </>
          </select>
          {errors.paymentAccount && (
            <Message
              type="error"
              className="mt-xs"
              message={errors.paymentAccount}
            />
          )}
        </div>

        <div className="flex flex-col items-start gap-xs">
          <label
            htmlFor="feeAccount"
            className="inline-block align-middle font-med text-primary-10"
          >
            Fee Account
          </label>
          <select
            id="feeAccount"
            name="feeAccount"
            // below mimics the disabled attribute but allows the value to be submitted rather than ignored
            className={classNames(
              "text-primary-10', w-full rounded-s border border-structure-80 bg-structure-100 p-m font-med disabled:bg-structure-95",
              {
                'pointer-events-none bg-structure-95 text-structure-60':
                  !!initial_feeAccount_id,
                'bg-structure-100': !!initial_feeAccount_id,
              },
            )}
            defaultValue={initial_feeAccount_id || ''}
            aria-readonly={!!initial_feeAccount_id}
          >
            <>
              <option value="" disabled>
                Select a Fee account
              </option>
              {feeAccounts?.map(({ account_id, name, code }) => (
                <option key={account_id} value={account_id}>
                  {name} - ({code})
                </option>
              ))}
            </>
          </select>
          {errors.feeAccount && (
            <Message
              type="error"
              className="mt-xs"
              message={errors.feeAccount}
            />
          )}
        </div>

        <div className="flex flex-col items-start gap-xs">
          <label
            id="invoiceThemesLabel"
            htmlFor="invoiceThemes"
            className="inline-block align-middle font-med text-primary-10"
          >
            Invoice Themes
          </label>
          <MultiSelect<TOption>
            defaultValue={initial_invoiceTheme_ids}
            value={selectedThemes}
            options={
              xeroInvoiceThemes?.data?.branding_themes?.map((theme) => ({
                label: theme.name || '',
                value: theme.branding_theme_id || '',
              })) || []
            }
            onChange={(themes) => setSelectedThemes(themes)}
            id="invoiceThemes"
            data-testid="invoiceThemesSelect"
            aria-labelledby="invoiceThemesLabel"
          />
          {errors.invoiceThemes && (
            <Message
              type="error"
              className="mt-xs"
              message={errors.invoiceThemes}
            />
          )}
          <a
            className="text-s font-bold text-primary-40 no-underline"
            href="https://central.xero.com/s/article/Add-edit-or-delete-custom-invoice-quote-templates"
            target="_blank"
            rel="noopener noreferrer"
          >
            Add a new theme in Xero
          </a>
        </div>

        <Button className="mt-4xl" type="submit" loading={isSubmitting}>
          Submit
        </Button>
        {!firstTime && (
          <button
            onClick={() =>
              unlinkXero(stateKey!, () => {
                // @ts-expect-error not in paths
                navigate({ to: '/pay/integrations/xero' });
              })
            }
            className="cursor-pointer appearance-none bg-transparent text-s font-bold text-primary-40 no-underline"
            type="button"
          >
            I'd like to un-link Xero
          </button>
        )}
      </form>
    </>
  );
};
