import { useState, Children } from 'react';

import { tracking } from '@iwoca/frontend-tracking-library';
import {
  fetchPutApplication,
  fetchPutState,
  GetStateResponse,
} from '@iwoca/lapi-client/edge';
import { Card } from '@iwoca/orion';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik, FormikValues } from 'formik';
import { cloneDeep, set, get } from 'lodash-es';
import { flushSync } from 'react-dom';
import * as Yup from 'yup';

import { ApplicantInformation } from './components/ApplicantInformation/ApplicantInformation';
import { BusinessInformation } from './components/BusinessInformation/BusinessInformation';
import { CrossSellCardIntro } from './components/CrossSellCardIntro/CrossSellCardIntro';
import { HeaderControls } from './components/HeaderControls/HeaderControls';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import styles from './CrossSellCard.module.css';
import { CrossSellFormValues } from './utils/CrossSellCard.types';
import {
  crossSellSteps,
  CrossSellSteps,
  incrementStep,
  stepIndex,
} from './utils/stepHandler';
import { CustomerRoles } from '../../api/lending/edge';
import {
  useGetApplication,
  useGetFormalOffers,
  useGetProductOffers,
  useGetStateByStateKey,
} from '../../api/lending/lapiHooks';
import {
  formaliseHighestOffer,
  checkHasOffer,
  getProductDeclineUid,
  checkStatusIsDeclined,
  declineProduct,
} from '../../Buyer/Signup/DecisionProvider';
import {
  denormaliseDate,
  normaliseStepData,
} from '../../Buyer/Signup/stateNormalisation';
import { useGetStateQuery } from '../../Buyer/Signup/steps/StepValidator';
import {
  getSuggestedProduct,
  SuggestedProductType,
} from '../../Buyer/utils/product';
import { buildQueryString } from '../../Buyer/utils/queryParams';
import { useOnce } from '../../hooks/useOnce';
import { useStateKey } from '../../hooks/useStateKey.hook';
import logger from '../../utils/logger';

export const CrossSellCard = () => {
  const { existingData, loadingExistingData } = useExistingApplicationData();
  const { formalOffers, loadingFormalOffers } = useGetFormalOffers();
  const { hasOpenApplication, loadingApplication } = useHasOpenApplication();

  if (loadingExistingData || loadingFormalOffers) return null;
  if (formalOffers?.formal_offers?.some((offer) => offer.is_valid)) return null;
  if (hasOpenApplication || loadingApplication) return null;

  return (
    <Card className={styles.card}>
      <CrossSellCardSteps initialValues={existingData}>
        <CrossSellCardIntro />
        <BusinessInformation />
        <ApplicantInformation />
        <PrivacyPolicy />
      </CrossSellCardSteps>
    </Card>
  );
};

const CrossSellCardSteps = ({ children, initialValues }: FormikValues) => {
  const [step, setStep] = useState<CrossSellSteps>('INTRODUCTION');
  const childrenArray = Children.toArray(children);
  const currentChild = childrenArray[stepIndex(step)];
  const { submitCrossSell } = useGetCrossSellOffers();

  useOnce({
    fn: () => tracking.ampli.displayedXSellBanner(),
  });

  return (
    <Formik
      initialValues={initialValues as CrossSellFormValues}
      onSubmit={async (values, { setTouched, validateForm }) => {
        // Flushing the state update, to ensure correct validation
        // on mount of the next step:
        // https://react.dev/reference/react-dom/flushSync
        flushSync(() => {
          setStep((currStep) => incrementStep(currStep));
        });
        await setTouched({});
        await validateForm();

        if (stepIndex(step) === crossSellSteps.length - 1) {
          await submitCrossSell(values);
          tracking.ampli.submittedXSellApplication();
        }
      }}
      validationSchema={getValidationSchema(step)}
      enableReinitialize
    >
      <div className={styles.formContainer}>
        <HeaderControls step={step} setStep={setStep} />
        <Form>{currentChild}</Form>
      </div>
    </Formik>
  );
};

const useExistingApplicationData = () => {
  const { data, isLoading } = useGetStateQuery(normaliseStepData);

  const existingData: CrossSellFormValues | null = isLoading
    ? null
    : {
        turnover: data['business-details']['last12MonthsTurnover'],
        amountRequested: '',
        startedTrading: data['business-details']['tradingStartDate'] || '',
        isDirector: data['business-details']['isDirector'],
        isShareholder: data['director-info']['isShareholder'],
        isVatRegistered: data['business-details']['isVatRegistered'],
        policyAgreed: false,
      };

  return { existingData, loadingExistingData: isLoading };
};

const getValidationSchema = (step: CrossSellSteps) => {
  switch (step) {
    case 'BUSINESS_INFORMATION':
      return businessValidation;
    case 'APPLICANT_INFORMATION':
      return applicantValidation;
    case 'PRIVACY_POLICY':
      return policyValidation;
    default:
      return null;
  }
};

const businessValidation = Yup.object().shape({
  turnover: Yup.number()
    .required('Turnover is required')
    .transform((_, v) => parseFloat(v.replace(/,/g, ''))),
  amountRequested: Yup.number()
    .required('Amount requested is required')
    .transform((_, v) => parseFloat(v.replace(/,/g, '')))
    .min(1000, 'This should be between 1,000 and 500,000')
    .max(1000000, 'This should be between 1,000 and 1,000,000'),
  startedTrading: Yup.string()
    .required('Started trading date is required')
    .test(
      'is-date',
      'Not a valid date',
      (value) =>
        !!(value && /^([0-3][0-9])\/([0-1][0-9])\/([0-9]{4})$/.test(value)),
    ),
});

const applicantValidation = Yup.object().shape({
  isDirector: Yup.boolean()
    .typeError('Selection required')
    .required('Selection required'),
  isShareholder: Yup.boolean()
    .typeError('Selection required')
    .required('Selection required'),
  isVatRegistered: Yup.boolean()
    .typeError('Selection required')
    .required('Selection required'),
});

const policyValidation = Yup.object().shape({
  policyAgreed: Yup.boolean().oneOf(
    [true],
    'Cannot submit without policy agreement',
  ),
});

const UTM_PARAMS = {
  utm_source: 'internal-website',
  utm_medium: 'iwocapay',
  utm_campaign: 'sellers_xsell_card',
};

const useGetCrossSellOffers = () => {
  const { stateKey } = useStateKey();
  const { state, refetchState, loadingState } = useGetStateByStateKey();
  const { fetchProductOffers, loadingProductOffers } = useGetProductOffers({
    enabled: false,
  });

  const { mutateAsync: putState, isPending } = useMutation({
    mutationFn: (state: GetStateResponse) => {
      if (!stateKey) {
        throw new Error('No statekey provided');
      }

      return fetchPutState({ stateKey, body: state });
    },
    onSuccess: async () => {
      await refetchState();
    },
  });

  function mergeNewRoles(
    prevRoles: CustomerRoles[] = [],
    roleChanges: { [key in CustomerRoles]?: boolean },
  ) {
    const changableRoles = Object.keys(roleChanges) as CustomerRoles[];
    const newRoles = prevRoles.filter((role) => !changableRoles.includes(role));

    for (const changableRole of changableRoles) {
      if (!roleChanges[changableRole]) continue;
      newRoles.push(changableRole);
    }

    return newRoles;
  }

  const updateUIStateShareholderQuestion = (
    newState: NonNullable<typeof state>,
    person: NonNullable<
      NonNullable<NonNullable<typeof state>['application']>['people']
    >[0],
  ) => {
    const answeredShareholderQuestion = get(
      newState,
      `ui.answeredIsShareholder.${person.uid}`,
    );

    if (!answeredShareholderQuestion) {
      set(newState, `ui.answeredIsShareholder.${person.uid}`, true);
    }
  };

  const updateStatePeople = (
    newState: NonNullable<typeof state>,
    values: CrossSellFormValues,
  ) => {
    const people = newState.application!.people;
    if (!people || people?.length < 1) return;

    people.forEach((person) => {
      if (!person.roles) return;
      if (!person.roles.includes('applicant')) return;

      updateUIStateShareholderQuestion(newState, person);

      person.roles = mergeNewRoles(person.roles, {
        shareholder: values.isShareholder === 'true',
        director: values.isDirector === 'true',
      });

      person.privacy_policy = {
        agreed: values.policyAgreed,
        datetime: new Date().toISOString(),
      };
    });
  };

  const updateStateCompany = (
    newState: NonNullable<typeof state>,
    values: CrossSellFormValues,
  ) => {
    const denormalisedTradingDate = denormaliseDate(values.startedTrading);
    set(
      newState,
      'application.company.trading_from_date',
      denormalisedTradingDate,
    );
    set(
      newState,
      'application.company.vat_status.is_vat_registered',
      values.isVatRegistered === 'true',
    );
    set(newState, 'application.company.last_12_months_turnover', {
      amount: parseInt(String(values.turnover).replace(/,/g, ''), 10),
      datetime: new Date().toISOString(),
    });
  };

  const updateState = async (values: CrossSellFormValues) => {
    if (!state) return;

    const newState = cloneDeep(state);

    updateStatePeople(newState, values);
    updateStateCompany(newState, values);
    set(newState, 'ui.hasCreatedFlexiApplication', true); // needed for reapply flow

    await putState({ data: newState });
  };

  const createFlexiApplication = async (values: CrossSellFormValues) => {
    await fetchPutApplication({
      stateKey: stateKey!,
      body: {
        data: {
          requested_products: [
            {
              product_type: 'credit_facility',
              amount: parseInt(
                String(values.amountRequested).replace(/,/g, ''),
                10,
              ),
              purpose: 'acquisition',
            },
          ],
        },
      },
    });
  };

  const getDecision = async () => {
    const { data: productOffers } = await fetchProductOffers();
    const suggestedProduct = getSuggestedProduct(productOffers!);

    if (!suggestedProduct) {
      logger.error('Missing suggested product', {
        stateKey: stateKey,
      });
      throw new Error('Missing suggested product');
    }

    const pendingDocsRequirement = checkStatusPendingDocs(suggestedProduct);
    if (pendingDocsRequirement) {
      return redirectToApplyFlexiView();
    }

    const isDeclined = checkStatusIsDeclined(suggestedProduct);
    if (isDeclined) {
      const productDeclineUid = getProductDeclineUid(suggestedProduct);

      await declineProduct({
        stateKey: stateKey!,
        productTypeDeclineUid: productDeclineUid!,
        status: suggestedProduct.status!,
      });

      return redirectToMyAccount();
    }

    const isApproved = checkHasOffer(suggestedProduct);
    if (isApproved) {
      await formaliseHighestOffer({
        stateKey: stateKey!,
        offers: suggestedProduct.offers,
      });

      return redirectToMyAccount();
    }

    // if not approved, send to re-engagement flow as a bit of a catch-all (I'll try improve this!)
    // Gonna throw a logging error here temporarily to see if people are hitting this
    logger.error('X-sell outstanding requirement not handled', {
      stateKey: stateKey,
      requirement: suggestedProduct.status,
    });
    return redirectToApplyFlexiView();
  };

  const submitCrossSell = async (values: CrossSellFormValues) => {
    await updateState(values);
    await createFlexiApplication(values);
    await getDecision();
  };

  return {
    submitCrossSell,
    isLoading: loadingState || isPending || loadingProductOffers,
  };
};

const checkStatusPendingDocs = (product: SuggestedProductType) => {
  return product?.status === 'pending_documents';
};

const redirectToMyAccount = () => {
  const queryString = buildQueryString(UTM_PARAMS);
  return (window.location.href = `/account/${queryString}`);
};

const redirectToApplyFlexiView = () => {
  const queryString = buildQueryString(UTM_PARAMS);
  return (window.location.href = `/account/apply-flexi-loan/${queryString}`);
};

const useHasOpenApplication = () => {
  const { application, loadingApplication } = useGetApplication();

  const hasOpenApplication = () => {
    if (!application) return false;
    if (
      application.status === 'open' &&
      application.requested_products?.some(
        (requestedProduct) =>
          requestedProduct.product_type === 'credit_facility',
      )
    )
      return true;

    return false;
  };

  return { hasOpenApplication: hasOpenApplication(), loadingApplication };
};
