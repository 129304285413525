import { useEffect, useState } from 'react';

import { fetchPutSellerPricing } from '@iwoca/lapi-client/iwocapay';
import { AlertBox, Button, Icon, Input, Radio, Switch } from '@iwoca/orion';

import { TPricingType } from '../../../../Buyer/PayLinkLanding/utils/PayLinkLanding.types';
import { useSellerPricing } from '../../../../hooks/useSellerPricing';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import { createToast } from '../../../../store/IwToast';
import { usePayIn12 } from '../../../PayLinks/hooks/usePayIn12';
import { ConfirmationModal } from '../../../PayLinks/ReusablePaylinkPage/ReusablePaylinkPage';

const InfoBox = ({ children }: { children: JSX.Element }) => {
  return (
    <AlertBox variant="cancel">
      <div className="grid-col grid grid-cols-[min-content,1fr] gap-s">
        <Icon fill="#437089" icon="infoCircleOutline" height={20} width={20} />
        <div className="font-reg">{children}</div>
      </div>
    </AlertBox>
  );
};

const TermInfo = ({
  productKey,
  pricingType,
  termEnabled,
}: {
  productKey: 'THREE_MONTHS' | 'TWELVE_MONTHS';
  pricingType?: TPricingType;
  termEnabled: boolean;
}): JSX.Element => {
  const { sellerPricing } = useSellerPricing();
  if (sellerPricing === null) return <></>;

  const termDetails = sellerPricing!.product_pricing_options[productKey]?.find(
    ({ pricing_type }) => pricing_type === pricingType,
  );

  const numberOfPayments = productKey === 'THREE_MONTHS' ? '3' : '12';

  if (!termDetails || !termEnabled)
    return (
      <>
        Turn on {numberOfPayments} months Pay Later to offer your customers the
        ability to spread the cost over {numberOfPayments} months.
      </>
    );

  if (pricingType === 'SELLER_PAYS_BUYER_FREE') {
    return (
      <>
        Your customers can Pay in {numberOfPayments} for free -{' '}
        <strong>
          you pay a fixed {termDetails.seller_fee_percentage}% fee of the total
          transaction value
        </strong>{' '}
        at the end of the month.
      </>
    );
  }

  if (pricingType === 'SELLER_FREE_BUYER_PAYS') {
    return (
      <>
        Your customers make{' '}
        <strong>{numberOfPayments} monthly payments including interest</strong>{' '}
        at a representative rate of{' '}
        {formatPricing(termDetails.buyer.representative_interest)}% - you don’t
        pay a penny.
      </>
    );
  }

  return <>Unknown</>;
};

export const formatPricing = (value: number) => {
  return parseFloat((value * 100).toFixed(2));
};

export const PayLinkInputs = ({
  amount,
  setAmount,
  reference,
  setReference,
  setPreviewValues,
  validateForm,
  setTouched,
  errors,
  touched,
}: {
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  reference: string;
  setReference: React.Dispatch<React.SetStateAction<string>>;
  setPreviewValues: (name: string, value: unknown) => void;
  validateForm: () => void;
  setTouched: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  errors: { [key: string]: string };
  touched: { [key: string]: string };
}) => {
  return (
    <div className="mb-xl grid grid-cols-2 grid-rows-1 gap-l sm:grid-cols-1 sm:grid-rows-2">
      <Input
        label="Amount"
        description="Your customer can’t change this."
        name="amount"
        type="number"
        value={amount}
        onChange={(ev) => {
          setAmount(ev.target.value);
          setPreviewValues('amount', ev.target.value);
          validateForm();
          setTouched({ ...touched, amount: 'true' });
        }}
        placeholder="Enter amount..."
        error={touched.amount && errors.amount}
      />
      <Input
        label="Reference"
        description="Your customer can’t change this."
        name="reference"
        value={reference}
        onChange={(ev) => {
          setReference(ev.target.value);
          setPreviewValues('reference', ev.target.value);
          validateForm();
          setTouched({ ...touched, reference: 'true' });
        }}
        placeholder="Enter reference..."
        error={touched.reference && errors.reference}
      />
    </div>
  );
};

export const PricingTermOption = ({
  productKey,
  pricingType,
  onChange,
  on12MonthsToggle,
}: {
  productKey: 'THREE_MONTHS' | 'TWELVE_MONTHS';
  pricingType?: TPricingType;
  onChange: (pricingType: TPricingType) => void;
  on12MonthsToggle?: (enabled: boolean) => void;
}) => {
  const { payIn12Enabled: payIn12EnabledGlobally } = usePayIn12();
  const [payIn12Enabled, setPayInTwelveEnabled] = useState(false);
  const termEnabled = productKey === 'THREE_MONTHS' ? true : payIn12Enabled;

  useEffect(
    () => setPayInTwelveEnabled(payIn12EnabledGlobally),
    [payIn12EnabledGlobally],
  );

  return (
    <div className="grid grid-cols-1 gap-m">
      <div className="grid grid-cols-[max-content,1fr] items-center">
        <h3 className="mb-0 mt-0 text-m">
          Pay in {productKey === 'THREE_MONTHS' ? '3' : '12'}
        </h3>
        {productKey === 'TWELVE_MONTHS' && (
          <div className="flex items-center justify-end gap-m">
            {termEnabled ? 'On' : 'Off'}
            <Switch
              checked={termEnabled}
              onClick={() => {
                setPayInTwelveEnabled((prev) => !prev);
                on12MonthsToggle?.(!termEnabled);
              }}
            />
          </div>
        )}
      </div>

      {termEnabled && (
        <div
          className="col-auto grid w-fit grid-cols-2 sm:grid-cols-1 sm:gap-l"
          data-testid={`pricing_${productKey}`}
        >
          <Radio
            checked={pricingType === 'SELLER_FREE_BUYER_PAYS'}
            name={productKey === 'THREE_MONTHS' ? 'pricing_3m' : 'pricing_12m'}
            value="SELLER_FREE_BUYER_PAYS"
            onChange={() => onChange('SELLER_FREE_BUYER_PAYS')}
            label="Free for you"
          />
          <Radio
            checked={pricingType === 'SELLER_PAYS_BUYER_FREE'}
            name={productKey === 'THREE_MONTHS' ? 'pricing_3m' : 'pricing_12m'}
            value="SELLER_PAYS_BUYER_FREE"
            onChange={() => onChange('SELLER_PAYS_BUYER_FREE')}
            label="Free for your customers"
          />
        </div>
      )}

      <InfoBox>
        <TermInfo
          productKey={productKey}
          pricingType={pricingType}
          termEnabled={termEnabled}
        />
      </InfoBox>
    </div>
  );
};

type TPricingTermOption = {
  enabled: boolean;
  pricingType: TPricingType;
};

type TPricingOptions = {
  THREE_MONTHS: TPricingTermOption;
  TWELVE_MONTHS: TPricingTermOption;
};

export const PayLaterPricingSettings = () => {
  const products = ['THREE_MONTHS', 'TWELVE_MONTHS'] as const;
  const [chosenPricing, setChosenPricing] = useState<TPricingOptions | null>(
    null,
  );
  const [confirmationModalState, setShowConfirmationModalState] = useState<
    'HIDDEN' | 'SHOWN' | 'PENDING'
  >('HIDDEN');
  const { sellerPricing, fetchSellerPricing } = useSellerPricing();
  const { togglePayIn12, payIn12Enabled } = usePayIn12();
  const { stateKey } = useStateKey();

  const savedPricingToChosen = () => {
    const enabledThreeMonths =
      sellerPricing?.product_pricing_options.THREE_MONTHS.find(
        ({ is_enabled }) => is_enabled,
      );
    const enabledTwelveMonths =
      sellerPricing?.product_pricing_options.TWELVE_MONTHS?.find(
        ({ is_enabled }) => is_enabled,
      );

    return {
      THREE_MONTHS: {
        enabled: !!enabledThreeMonths,
        pricingType:
          enabledThreeMonths?.pricing_type || 'SELLER_FREE_BUYER_PAYS',
      },
      TWELVE_MONTHS: {
        enabled: !!enabledTwelveMonths,
        pricingType:
          enabledTwelveMonths?.pricing_type || 'SELLER_FREE_BUYER_PAYS',
      },
    };
  };

  const resetToDefaults = () => setChosenPricing(savedPricingToChosen);

  if (sellerPricing !== null && chosenPricing === null) resetToDefaults();
  if (chosenPricing === null) return <></>;

  const switchPricing = ({
    pricingTerm,
    productKey,
  }: {
    pricingTerm: TPricingType;
    productKey: 'THREE_MONTHS' | 'TWELVE_MONTHS';
  }) => {
    if (chosenPricing === null) return;

    setChosenPricing({
      ...chosenPricing,
      [productKey]: {
        ...chosenPricing[productKey],
        enabled: productKey === 'THREE_MONTHS' ? true : payIn12Enabled,
        pricingType: pricingTerm,
      },
    });
  };

  const hasChanged = () => {
    /*
      Note: We compare the terms rather than chosenPricing vs savedPricingToChosen()
      as we don't want to detect a change in the enabled state
     */
    const threeMonthChanged =
      chosenPricing.THREE_MONTHS.pricingType !==
      savedPricingToChosen().THREE_MONTHS.pricingType;
    const twelveMonthChanged =
      chosenPricing.TWELVE_MONTHS.pricingType !==
      savedPricingToChosen().TWELVE_MONTHS.pricingType;

    return threeMonthChanged || twelveMonthChanged;
  };

  return (
    <>
      <div className="mt-xl grid grid-cols-1 gap-xl">
        {products.map((productKey) => (
          <PricingTermOption
            productKey={productKey}
            key={productKey}
            pricingType={chosenPricing[productKey].pricingType}
            onChange={(pricingType) =>
              switchPricing({ pricingTerm: pricingType, productKey })
            }
            on12MonthsToggle={async (enabled) => {
              await togglePayIn12();
              createToast(`Switched ${enabled ? 'off' : 'on'} Pay in 12`);
              await fetchSellerPricing();
              resetToDefaults();
            }}
          />
        ))}

        {hasChanged() && (
          <div className="mt-xl grid w-fit grid-cols-2 gap-s sm:w-full">
            <Button
              type="submit"
              onClick={() => setShowConfirmationModalState('SHOWN')}
            >
              Save changes
            </Button>
            <Button variant="secondary" onClick={() => resetToDefaults()}>
              Revert changes
            </Button>
          </div>
        )}
      </div>

      <ConfirmationModal
        isOpen={confirmationModalState !== 'HIDDEN'}
        isSubmitting={confirmationModalState === 'PENDING'}
        onConfirm={async () => {
          setShowConfirmationModalState('PENDING');
          const values = {
            THREE_MONTHS: chosenPricing.THREE_MONTHS.pricingType,
            ...(chosenPricing.TWELVE_MONTHS.enabled && {
              TWELVE_MONTHS: chosenPricing.TWELVE_MONTHS.pricingType,
            }),
          };

          try {
            await fetchPutSellerPricing({
              stateKey: stateKey!,
              body: {
                active_product_pricing: values,
              },
            });

            await fetchSellerPricing();
            setShowConfirmationModalState('HIDDEN');
          } catch (e) {
            createToast('Unable to save changes. Please try again.');
          }
        }}
        onCancel={() => {
          setShowConfirmationModalState('HIDDEN');
          resetToDefaults();
        }}
      />
    </>
  );
};
