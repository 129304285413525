import { useEffect, useState } from 'react';

import {
  fetchGetXeroConnections,
  fetchPostXeroConnect,
  fetchPostXeroIwocapaySellerAccount,
} from '@iwoca/lapi-client/edge';
import { Spinner } from '@iwoca/orion';

import { ConnectError } from './Authorisation.types';
import { AuthorisationFailed } from './AuthorisationFailed';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { XeroSetupForm } from '../XeroSetupForm/XeroSetupForm';

export const Authorisation = () => {
  const { stateKey } = useStateKey();
  const [error, setError] = useState<'Checking' | ConnectError | null>(
    'Checking',
  );

  useEffect(() => {
    const catchErrors = async () => {
      try {
        const { code, state } = Object.fromEntries(
          new URLSearchParams(window.location.search),
        );
        // clears backend session storage
        await fetchPostXeroConnect({
          reason: 'iwocapay_seller',
          stateKey: stateKey!,
          body: {
            data: {
              code,
              state,
            },
          },
        });
        // fetches xero connections
        const connections = await fetchGetXeroConnections({
          reason: 'iwocapay_seller',
          stateKey: stateKey!,
        });
        const xero_tenantid = connections.data?.connections?.[0]?.tenant_id;
        if (!xero_tenantid) {
          throw new Error('No Xero tenant ID found');
        }
        // sets up xero iwocapay seller account
        await fetchPostXeroIwocapaySellerAccount({
          stateKey: stateKey!,
          body: { data: { xero_tenantid } },
        });
      } catch (error) {
        // Causes problems locally shouldn't be an issue in prod
        setError('AuthorisationFailed');
        console.error('Post Xero Connect failed', error);
        return;
      }

      setError(null);
    };

    if (stateKey) {
      void catchErrors();
    }
  }, [stateKey]);

  if (error === null) {
    return <XeroSetupForm />;
  }

  return (
    <div className="flex h-full w-full items-center justify-center text-center">
      <div className="h-fit w-fit text-structure-0">
        {error === 'AuthorisationFailed' && <AuthorisationFailed />}
        {error === 'Checking' && (
          <>
            <Spinner size="large" />
            <h2>Please wait</h2>
            <p>You are being redirected from Xero</p>
          </>
        )}
      </div>
    </div>
  );
};
